import dayjs from 'dayjs'

export const convertToDate = (date: string, format = 'DD/MM/YYYY'): string => {
	return date ? dayjs(date).format(format) : '-'
}

export const convertToTime = (time: string, format = 'HH:MM'): string => {
	return time ? dayjs(time).format(format) : '-'
}

export const formatDefault = (dateTime: string, format = 'DD-MM-YYYY HH:mm'): string => {
	return dateTime ? dayjs(dateTime).format(format) : '-'
}

export default dayjs