import { AxiosResponse } from 'axios'
import axios from '../instance'
import { IBillPaymentListPayload, ITransactionList } from './type'

export const getBillPayment = async (payload: IBillPaymentListPayload): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/admin/bill-payment-list', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getTransactionList = async (payload: ITransactionList): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/admin/transaction-list', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const inquiryTransaction = async (tranId: string): Promise<AxiosResponse> => {
	try {
		const response = await axios.get(`/admin/inquiry-transaction/${tranId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const updateBillPayment = async (billPaymentId: string): Promise<AxiosResponse> => {
	try {
		const response = await axios.put('/admin/update-bill-payment', {
			billPaymentId
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}