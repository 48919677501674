export enum PAYMENT_STATUS {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED'
}

export interface IBillPaymentListPayload {
    offset: number;
    limit: number;
    status: PAYMENT_STATUS;
    shops: string[];
    startDate: string;
    endDate: string;
}

export enum TRANSACTION_STATUS {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    EXPIRED = 'EXPIRED',
    FAILED = 'FAILED',
    SETTLED = 'SETTLED',
  }

export interface ITransactionList {
    offset: number;
    limit: number;
    billPaymentId: string;
}